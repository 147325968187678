.timeline-centered {
  position: relative;
  margin-bottom: 30px; }

.timeline-centered:before, .timeline-centered:after {
  content: " ";
  display: table; }

.timeline-centered:after {
  clear: both; }

.timeline-centered:before, .timeline-centered:after {
  content: " ";
  display: table; }

.timeline-centered:after {
  clear: both; }

.timeline-centered:before {
  content: '';
  position: absolute;
  display: block;
  width: 4px;
  background: #f2f3f7;
  top: 20px;
  bottom: 20px;
  margin-left: 29px; }

.timeline-centered .timeline-entry {
  position: relative;
  margin-top: 5px;
  margin-left: 30px;
  margin-bottom: 10px;
  clear: both; 
  transition: 0.3s;
}

.timeline-entry-inner:hover {
  cursor: pointer;
  transform: scale(1.03);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.timeline-centered .timeline-entry .timeline-entry-inner {
  position: relative;
  margin-left: -20px;
  transition: 0.3s;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon {
  display: block;
  width: 40px;
  height: 40px;
  background: #2c98f0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
  -moz-box-shadow: 0 0 0 5px #f2f3f7;
  -webkit-box-shadow: 0 0 0 5px #f2f3f7;
  box-shadow: 0 0 0 5px #f2f3f7;
  line-height: 40px;
  float: left; }
  .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon i {
    color: #fff; }
  .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.color-2 {
    background: #ec5453; }
  .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.color-3 {
    background: #f9bf3f; }
  .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.color-4 {
    background: #a84cb8; }
  .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.color-5 {
    background: #2fa499; }
  .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.color-none {
    background: #fff;
    width: 20px;
    height: 20px;
    margin-left: 10px; }

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label {
  position: relative;
  background: #f2f3f7;
  padding: 1.5em;
  margin-left: 60px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box; }

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label:after {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 9px 9px 9px 0;
  border-color: transparent #f2f3f7 transparent transparent;
  left: 0;
  top: 10px;
  margin-left: -9px; }

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label p + p {
  margin-top: 15px; }

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label h2 {
  font-size: 20px;
  font-family: "Quicksand", Arial, sans-serif;
  font-weight: 500; }

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label h2 a {
  color: #000; }

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label h2 span {
  -webkit-opacity: .4;
  -moz-opacity: .4;
  opacity: .4;
  -ms-filter: alpha(opacity=40);
  filter: alpha(opacity=40);
  font-size: 16px; }
